<template>
	<div class="searchbar">
		<el-button class="reset" size="small" type="info" plain icon="el-icon-refresh-right" @click="reset"></el-button>
		<el-input :placeholder="placeholder" v-model="inputText" clearable size="small"></el-input>
		<el-button class="search" size="small" type="info" plain icon="el-icon-search" @click="searchList"></el-button>
	</div>
</template>

<script>
	export default {
		props: {
			queryOptions: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				inputText: "",
				queryCfg:{},
				placeholder:""
			}
		},
		created() {
			console.log(this.queryOptions)
			if(this.queryOptions.placeHolder)
				this.placeholder = "请输入"+this.queryOptions.placeHolder+"进行查询"
			else
				this.placeholder = "请输入查询条件"
		},
		methods:{
			searchList(){
				const object = new Object();
				object[(this.queryOptions.keys)] = this.inputText.trim()
				this.$emit("search",object)
			},
			reset(){
				this.inputText = ""
				this.$vue.$emit("reset")
				this.$emit("reset")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.searchbar {
		@include flex-style(row,nowrap,flex-start,center);
		box-sizing: border-box;
		max-width:540px;
		button{
			color: #000000;
		}
		margin-right: 16px;
		/deep/.el-input__inner{
			border-radius:0;
			text-align: center;
		}
		.reset{
			border-radius: 4px 0 0 4px;
			border-right: 0;
		}
		.search{
			border-radius: 0 4px 4px 0;
			border-left: 0;
		}
	}
</style>
